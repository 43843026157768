export const hasFilter = (filters, appliedFilter) => {
  if (filters.length <= 0) return true
  if (filters.length >= 0) {
    let filterMatch = false
    filters.forEach(filter => {
      if (filter.value === appliedFilter) {
        filterMatch = true
      }
    })
    return filterMatch
  }
}
