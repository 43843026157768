import { useState } from "react"

export const useFAQs = () => {
    const [FAQStates, setFAQStates] = useState({
        FAQs: [],
        isLoading: true
    })

    const handleInitialState = loadedResources => {
        setFAQStates({
            FAQs: loadedResources,
            isLoading: false
        })
    }

    return { FAQStates, handleInitialState };
}