import { useState } from "react"
import { hasFilter} from "@utilities"
import { FILTERS_TO_IGNORE } from "../constants"

export const useResources = () => {
    const [resourcesStates, setResourcesStates] = useState({
        allResources: [],
        searchedResources: [],
        results: [],
        filterKeys: [],
        filters: {},
        isLoading: true
    })

    const handleInitialState = loadedResources => {
        if (loadedResources.length === 0) {
            setResourcesStates(currentState => ({
                ...currentState,
                isLoading: false
            }));
            return
        }

        let filterKeys = []
        let filters = {}
        const resourceKeys = Object.keys(loadedResources[0])

        for (let i = 0; i < resourceKeys.length; i++) {
            const currentKey = resourceKeys[i]
            if (FILTERS_TO_IGNORE.filter(item => currentKey === item).length === 0) {
                filterKeys.push(currentKey)
                filters[currentKey] = []
            }
        }

        setResourcesStates({
            allResources: loadedResources,
            searchedResources: loadedResources,
            results: loadedResources,
            filterKeys: filterKeys,
            filters: filters,
            isLoading: false
        })
    }

    const handleFilters = filters => {
        if (resourcesStates.allResources.length === 0) return;
        if (resourcesStates.filterKeys.length <= 0) return resourcesStates.searchedResources

        let filtered = []
        resourcesStates.searchedResources.forEach((resource, index) => {
            let match = Array.from({ length: resourcesStates.filterKeys.length }, () => true)
            for (let i = 0; i < resourcesStates.filterKeys.length; i++) {
                const resourceKey = resourcesStates.filterKeys[i]
                if (!filters.hasOwnProperty(resourceKey)) {
                    match[i] = true
                    continue
                }
                match[i] = hasFilter(filters[resourceKey], resource[resourceKey])
            }
            if (match.every(e => e === true)) filtered.push(resource)
        })
        setResourcesStates(currentState => ({
            ...currentState,
            results: filtered,
            filters: filters
        }))
    }

    const handleSearch = searchTerm => {
        let searched = []
        resourcesStates.allResources.forEach((resource, index) => {
            if (searchTerm.length <= 0 || searchTerm === "") searched.push(resource)
            else if (
                resource.title.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
            )
                searched.push(resource)
        })
        setResourcesStates(currentState => ({
            ...currentState,
            searchedResources: searched,
            results: searched
        }))
    }

    return { resourcesStates, handleInitialState, handleSearch, handleFilters }
}